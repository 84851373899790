import React from 'react'
import { connect } from "react-redux"

import { Notification } from '../common'
import { toggleLoginModal, showNotification } from '../../redux/actions/ui'
import {
  login,
  signUp,
  updatePhone,
  sendCode,
  sendConfirmationCode,
  save,
  fbLogin,
  googleLogin,
  forgotPassword
} from '../../redux/actions/auth'

import { handleGTM } from '../../redux/actions/gtm'

import { LoginPanel, LoginForm, SignUpForm } from '.'

const MODAL_STATES = {
  LOGIN_PANEL: 0,
  LOGIN_FORM: 1,
  SIGNUP_FORM: 2
}

class Login extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      step: 0,
      confirmationType: 'sms',
      confirmationErrors: 0,
      phone: '',
      ddi: 55,
      code: '',
      time: 30,
      tempUser: {
        name: null,
        lastName: null,
        email: null,
        image: null
      },
      error: null,
      method: null
    }
  }

  setInterval = async () => {
    if (this.state.time < 1) {
      return
    }

    this.setTime(this.state.time - 1)
  }

  componentDidMount() {
    setInterval(this.setInterval, 1000)
  }

  showError = (error) => {
    this.setState({ error })
  }

  dismissError = () => {
    this.setState({ error: null })
  }

  addEventGTM = (args) => {
    this.props.handleGTM(args)
  }

  showLoginForm = () => {
    const dataGTM = {
      'event': 'sign_up',
      'method': 'Email',
      'step': '1'
    }

    this.setState({ method: 'Email' })
    this.addEventGTM(dataGTM)

    this.props.toggleLoginModal({ show: true, state: MODAL_STATES.LOGIN_FORM })
  }

  showSignUpForm = () => {
    const dataGTM = {
      'event': 'sign_up',
      'method': '',
      'step': '2'
    }

    this.addEventGTM(dataGTM)

    this.props.toggleLoginModal({ show: true, state: MODAL_STATES.SIGNUP_FORM })
  }

  setTime = (time) => {
    this.setState({ time })
  }

  setDDI = (ddi) => {
    this.setState({ ddi })
  }

  setPhone = async (phone) => {
    if (phone) {
      await this.setState({
        phone,
        rawPhone: `${this.state.ddi}${phone.replace(/\(|\)|-|\s/g, '')}`,
        fullPhone: `+${this.state.ddi} ${phone}`
      })
    } else {
      this.setState({
        phone: '',
        rawPhone: '',
        fullPhone: ''
      })
    }
  }

  setCode = (code) => {
    this.setState({ code })
  }

  setStep = (step) => {
    this.setState({ step })
  }

  setConfirmationType = (confirmationType) => {
    this.setState({ confirmationType: confirmationType })
  }

  setConfirmationErrors = (confirmationErrors) => {
    this.setState({ confirmationErrors: confirmationErrors })
  }

  handleClose = () => {
    this.props.toggleLoginModal({ show: false })
  }

  handleEmailLogin = async (email, pass) => {
    this.dismissError()
    const result = await this.props.login({ email, pass })

    if (result.user && result.user.signUpStatus === 1) {
      this.handleClose()
      this.props.onSuccess()

      return
    }

    if (result.user && result.user.signUpStatus === 2 && result.success) {
      this.setStep(1)

      return
    }

    if (result.error) {
      this.showError(result.error)
    }
  }

  handleSave = async (form) => {
    this.dismissError()

    const data = {
      ...this.props.data,
      ...form
    }

    await this.props.save(data)
  }

  sendCode = async (form) => {
    const result = await this.props.sendCode({
      ...form,
      resend: this.state.confirmationErrors >= 1 ? true : false,
      confirmationType: this.state.confirmationType
    })

    const {
      error,
      success
    } = result

    if (!success) {
      this.setState({
        error
      })

      this.showError(error)

      return result
    }

    this.setState({
      error: null
    })

    return result
  }

  sendConfirmationCode = async (form) => {
    const {
      code,
      confirmationType
    } = this.state

    const result = await this.props.sendConfirmationCode({
      ...form,
      code,
      confirmationType
    })

    const {
      error,
      success
    } = result

    if (!success && this.state.confirmationErrors >= 1) {
      await this.setState({
        confirmationType: 'phone'
      })
    }

    if (!success) {
      await this.setState({
        error: error.message,
        confirmationErrors: Number(this.state.confirmationErrors) + 1
      })

      this.showError(error)

      return result
    }

    this.dismissError()
    this.handleClose()
    this.setStep(0)

    if (typeof this.props.onSuccess === 'function') {
      this.props.onSuccess()
    }

    this.setState({
      error: null
    })

    return result
  }

  signUp = async (data) => {
    const result = await this.props.signUp(data)

    const {
      error,
      success
    } = result

    if (!success) {
      this.setState({
        error
      })

      this.showError(error)

      this.setStep(0)
      this.setConfirmationErrors(0)
      this.setConfirmationType('sms')

      return
    }

    this.setState({
      error: null
    })

    return result
  }

  updatePhone = async (data) => {
    if (!data) {
      this.dismissError()
      this.handleClose()

      this.setState({
        error: null
      })

      return
    }

    const result = await this.props.updatePhone(data)

    return result
  }

  handleForgotPassword = async (email) => {
    this.dismissError()
    const result = await this.props.forgotPassword(email)
    if (result.success) {
      this.props.showNotification({
        type: 'success',
        text: 'Uma nova senha foi enviada ao seu email'
      })
      this.handleClose()
    } else {
      this.showError(result.error)
    }
  }

  handleFbLogin = async (form) => {
    const dataGTM = {
      'event': 'sign_up',
      'method': 'Facebook',
      'step': '1'
    }

    this.setState({ method: 'Facebook' })
    this.addEventGTM(dataGTM)

    if (!form.accessToken) {
      this.showError("Erro ao realizar o login com o Facebook, por favor tente novamente")

      return
    }

    this.dismissError()

    const result = await this.props.fbLogin(form)

    this.handleSocialLoginResponse(result)
  }

  handleGoogleLogin = async (form) => {
    const dataGTM = {
      'event': 'sign_up',
      'method': 'Google',
      'step': '1'
    }

    this.setState({ method: 'Google' })
    this.addEventGTM(dataGTM)

    if (form.error) {
      this.showError("Erro ao realizar o login com o Google, por favor tente novamente")

      return
    }

    this.dismissError()

    const result = await this.props.googleLogin(form)
    this.handleSocialLoginResponse(result)
  }

  handleSocialLoginResponse = (result) => {
    if (result.success) {
      this.handleClose()
    } else if (result.signUp) {
      this.setState({
        tempUser: {
          name: result.user.name,
          lastName: result.user.lastName,
          email: result.user.email,
          image: result.user.image
        }
      })
      this.showSignUpForm()
    } else {
      this.showError(result.error)
    }
  }

  renderContent = () => {
    switch (this.props.currentState) {
      case MODAL_STATES.LOGIN_PANEL:
        return <LoginPanel
          showLogo={false}
          mode={this.props.mode}
          showLoginForm={this.showLoginForm}
          showSignUpForm={this.showSignUpForm}
          onFacebookLogin={this.handleFbLogin}
          onGoogleLogin={this.handleGoogleLogin}
          logo={this.props.storeLogo}
        />
      case MODAL_STATES.LOGIN_FORM:
        return <LoginForm
          loading={this.props.loginLoading}
          updatePhone={this.updatePhone}
          save={this.handleSave}
          step={this.state.step}
          setStep={this.setStep}
          ddi={this.state.ddi}
          setDDI={this.setDDI}
          rawPhone={this.state.rawPhone}
          fullPhone={this.state.fullPhone}
          phone={this.state.phone}
          setPhone={this.setPhone}
          code={this.state.code}
          setCode={this.setCode}
          sendCode={this.sendCode}
          sendConfirmationCode={this.sendConfirmationCode}
          confirmationErrors={this.state.confirmationErrors}
          setConfirmationErrors={this.setConfirmationErrors}
          onLogin={this.handleEmailLogin}
          onSignUp={this.showSignUpForm}
          user={this.props.user}
          error={this.state.error}
          data={this.props.data}
          time={this.state.time}
          setTime={this.setTime}
          setConfirmationType={this.setConfirmationType}
          confirmationType={this.state.confirmationType}
          onForgotPassword={this.handleForgotPassword}
          logo={this.props.storeLogo}
          handleGTM={this.props.handleGTM}
        />
      case MODAL_STATES.SIGNUP_FORM:
        return <SignUpForm
          loading={this.props.signupLoading}
          signUp={this.signUp}
          save={this.handleSave}
          step={this.state.step}
          setStep={this.setStep}
          ddi={this.state.ddi}
          setDDI={this.setDDI}
          rawPhone={this.state.rawPhone}
          fullPhone={this.state.fullPhone}
          phone={this.state.phone}
          setPhone={this.setPhone}
          code={this.state.code}
          setCode={this.setCode}
          sendCode={this.sendCode}
          sendConfirmationCode={this.sendConfirmationCode}
          confirmationErrors={this.state.confirmationErrors}
          setConfirmationErrors={this.setConfirmationErrors}
          user={this.props.user}
          error={this.state.error}
          data={this.props.data}
          setConfirmationType={this.setConfirmationType}
          confirmationType={this.state.confirmationType}
          time={this.state.time}
          setTime={this.setTime}
          name={this.state.tempUser.name}
          lastName={this.state.tempUser.lastName}
          email={this.state.tempUser.email}
          image={this.state.tempUser.image}
          logo={this.props.storeLogo}
          handleGTM={this.props.handleGTM}
        />
      default:
        return <></>
    }
  }

  render() {
    return (
      <>
        <Notification type="error" text={this.state.error} onDismiss={this.dismissError} timeout={5} />

        {this.renderContent()}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    isOpen: state.ui.modals.loginModal.show,
    currentState: state.ui.modals.loginModal.state,
    loginLoading: state.requests.login.loading || state.requests.forgotPassword.loading,
    signupLoading: state.requests.signup.loading,
    data: state.requests.save,
    user: state.user,
    storeLogo: state.store && state.store.logo
  }
}

export default connect(
  mapStateToProps, {
  login,
  signUp,
  updatePhone,
  save,
  sendCode,
  sendConfirmationCode,
  fbLogin,
  googleLogin,
  forgotPassword,
  showNotification,
  toggleLoginModal,
  handleGTM
}
)(Login)
