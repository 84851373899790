import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'

import { device } from '../../constants/responsive'
import { Modal } from '../common'
import { CitySelect, StoreList } from './'
import { toggleAddressModal } from '../../redux/actions/ui'
import useLocation from '../../utils/useLocation'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 55px;
  height: 470px;

  @media ${device.desktop} {
    width: 360px;
    margin: 0px;
    padding: 30px;
  }
`

const Title = styled.div`
  color: ${props => props.theme.colors.primary};
  font-size: 18px;
  font-weight: 700;
`

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${props => props.theme.colors.primary};
  margin-top: 5px;
  margin-bottom: 25px;
`

export const MODAL_STATES = {
  CITY_SELECT: 0,
  STORE_SELECT: 1
}

export const StoreSelectModal = (props) => {
  const { handleGTM } = props

  const [modalState, setModalState] = useState(MODAL_STATES.CITY_SELECT)
  const availableCities = useSelector(state => state.store.availableCities)
  const availableStores = useSelector(state => state.store.availableStores)
  const citiesLoading = useSelector(state => state.requests.availableCities.loading)
  const storesLoading = useSelector(state => state.requests.availableStores.loading)
  const dispatch = useDispatch()
  const { coords } = useLocation()

  useEffect(() => {
    if (coords) {
      setModalState(MODAL_STATES.STORE_SELECT)
      props.getAvailableStores({ coords })
    } else {
      props.getAvailableCities()
    }
  }, [coords, dispatch, props])

  const addEventGTM = (args) => {
    handleGTM(args)
  }

  const handleSelectCity = (selectedCity) => {
    setModalState(MODAL_STATES.STORE_SELECT)
    props.getAvailableStores({ city: selectedCity, coords })
  }

  const handleSelectStore = (selectedStore) => {
    const { id, name } = selectedStore
    dispatch(toggleAddressModal({ show: false }))
    const dataGTM = {
      'event': 'escolha_estabelecimento',
      'affiliation': name
    }

    addEventGTM(dataGTM)
    props.setSelectedStore(id)

    props.getStoreInfoAndMenu(id)

    setTimeout(() => {
      window.location.reload(false)
    }, 1000)
  }

  const renderModalContent = () => {
    switch (modalState) {
      case MODAL_STATES.CITY_SELECT:
        return (
          <CitySelect
            availableCities={availableCities}
            loading={citiesLoading}
            onSelect={handleSelectCity}
          />
        )
      case MODAL_STATES.STORE_SELECT:
        return (
          <StoreList
            availableStores={availableStores}
            loading={storesLoading}
            onSelect={handleSelectStore}
          />
        )
      default:
        return <></>
    }
  }

  return (
    <Modal isOpen={true} disableClose>
      <Wrapper>
        <Title>RETIRAR NA LOJA</Title>
        <Divider>&nbsp;</Divider>
        {renderModalContent()}
      </Wrapper>
    </Modal>
  )
}
