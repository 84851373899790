import React from 'react'
import styled from 'styled-components'

import { Loading } from '../common/'
import { StoreCard } from './'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
`

const Title = styled.span`
    color: ${props => props.theme.colors.textBold};
    font-size: 18px;
    font-weight: bold;
`

const List = styled.div`
    overflow-y: auto;
    max-height: 300px;
    width: 100%;
`

export const StoreList = ({availableStores, selectedCity, loading, onSelect}) => {

    const handleSelect = (storeId) => () => {
        onSelect(storeId)
    }

    if (loading) {
        return <Loading />
    }

    return (
        <Wrapper>
            <Title>
                {selectedCity || `Selecione um restaurante`}
            </Title>
            <List>
                {availableStores && availableStores.map(store => (
                    <StoreCard
                        store={store}
                        onClick={handleSelect({id:store.id, name: store.name})}
                    />
                ))}
            </List>
        </Wrapper>
    )
}