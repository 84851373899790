import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { TextInput, Button, LoadingOverlay } from "../common";
import { Form } from "../common/form/";
import { device } from "../../constants/responsive";

import { notEmpty } from "../common/form/validationFunctions";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;

  @media ${device.desktop} {
    width: 440px;
  }
`;

const TitleText = styled.span`
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.01em;
  color: ${(props) => props.theme.colors.textBold};
`;

const SingleColumn = styled.div`
  width: 100%;
  padding: 16px 0 16px 0;
  text-align: center;
`;

const DoubleColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 16px 0 16px 0;
`;

const BlankSpace = styled.div`
  width: 45px;
`;

class AddressDetailForm extends React.Component {
  constructor(props) {
    super(props);
    this.formInputs = this.setupForms();
  }

  handleSaveAddress = (address) => {
    this.props.addEventGTM({
      'event': 'adress_info',
      'location_id': ''
    })
    this.props.saveAddress(address);
  };

  setupForms = () => [
    {
      id: "street",
      validations: [notEmpty],
      defaultValue: this.props.address.street,
      Component: (formInputProps) => (
        <TextInput
          {...formInputProps}
          placeholder="Endereço *"
          keepPlaceholderAbove
        />
      ),
    },
    {
      id: "number",
      validations: [notEmpty],
      defaultValue: this.props.address.number,
      Component: (formInputProps) => (
        <TextInput
          {...formInputProps}
          placeholder="Número *"
          keepPlaceholderAbove
        />
      ),
    },
    {
      id: "complement",
      validations: [],
      defaultValue: this.props.address.complement,
      Component: (formInputProps) => (
        <TextInput
          {...formInputProps}
          placeholder="Complemento"
          keepPlaceholderAbove
        />
      ),
    },
    {
      id: "cep",
      validations: [notEmpty],
      defaultValue: this.props.address.cep,
      Component: (formInputProps) => (
        <TextInput
          {...formInputProps}
          placeholder="CEP *"
          keepPlaceholderAbove
          mask={"99999-999"}
        />
      ),
    },
    {
      id: "neighborhood",
      validations: [notEmpty],
      defaultValue: this.props.address.neighborhood,
      Component: (formInputProps) => (
        <TextInput
          {...formInputProps}
          placeholder="Bairro *"
          keepPlaceholderAbove
        />
      ),
    },
    {
      id: "city",
      validations: [notEmpty],
      defaultValue: this.props.address.city,
      Component: (formInputProps) => (
        <TextInput
          {...formInputProps}
          placeholder="Cidade *"
          keepPlaceholderAbove
        />
      ),
    },
    {
      id: "state",
      validations: [notEmpty],
      defaultValue: this.props.address.state,
      Component: (formInputProps) => (
        <TextInput
          {...formInputProps}
          placeholder="Estado *"
          keepPlaceholderAbove
        />
      ),
    },
    {
      id: "reference",
      validations: [],
      defaultValue: this.props.address.reference,
      Component: (formInputProps) => (
        <TextInput
          {...formInputProps}
          placeholder="Referência"
          keepPlaceholderAbove
        />
      ),
    },
  ];

  render() {
    return (
      <Wrapper>
        <LoadingOverlay visible={this.props.loading} margin={"5px"} />
        <Form formInputs={this.formInputs} onSubmit={this.handleSaveAddress}>
          {(inputs) => {
            return (
              <div>
                <SingleColumn>
                  <TitleText>Endereço de Entrega</TitleText>
                </SingleColumn>
                <SingleColumn>{<inputs.street />}</SingleColumn>
                <DoubleColumn>
                  {<inputs.number />}
                  <BlankSpace />
                  {<inputs.complement />}
                </DoubleColumn>
                <DoubleColumn>
                  {<inputs.cep />}
                  <BlankSpace />
                  {<inputs.neighborhood />}
                </DoubleColumn>
                <DoubleColumn>
                  {<inputs.city />}
                  <BlankSpace />
                  {<inputs.state />}
                </DoubleColumn>
                <SingleColumn>
                  <inputs.reference />
                </SingleColumn>
                <SingleColumn>
                  <Button type="submit" className="action">
                    SALVAR
                  </Button>
                </SingleColumn>
              </div>
            );
          }}
        </Form>
      </Wrapper>
    );
  }
}

AddressDetailForm.propTypes = {
  address: PropTypes.shape({
    street: PropTypes.string,
    number: PropTypes.string,
    complement: PropTypes.string,
    neighborhood: PropTypes.string,
    cep: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    reference: PropTypes.string,
  }),
};

AddressDetailForm.defaultProps = {
  address: {},
};

export default AddressDetailForm;
