import styled from 'styled-components'

import { device } from '../../constants/responsive';

const Button = styled.button`
  background-color: #ffffff;
  border-style: solid;
  border-radius: 5px;
  border-size: 1px;
  border-width: 1px;
  width: ${props => props.stretch ? '100%': '128px'};
  height: 30px;
  font-size: 12px;
  line-height: 12px;
  transition: all 0.1s;

  @media ${device.desktop} {
    font-size: ${props => props.big ? '16px' : '13px'};
    width: ${props => props.stretch ? '100%': '152px'};
    padding: 5px;
  }

  &.default{
    border-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.primary};
    &:hover {
      background-color: ${props => props.theme.colors.primary};
      color: white;
    }
  }

  &.noborder{
    border-color: none;
    margin-top: 10px;
    color: ${props => props.theme.colors.primary};
  }

  &.hollow {
    color: ${props => props.theme.colors.textPrimary};
    border: none;
    background-color: none;
  }

  &.primary {
    border-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.primary};
    background-color: ${props => props.theme.colors.primary};
    color: white;
    &:hover {}
    &:disabled {
      opacity: 0.4;
      pointer-events: none;
    }
  }

  &.action {
    background-color: ${props => props.theme.colors.textAction};
    border-color: ${props => props.theme.colors.textAction};
    color: white;
    &:hover {}
  }

  &.back {
    background-color: ${props => props.theme.colors.mediumGrey};
    border-color: ${props => props.theme.colors.mediumGrey};
    color: white;
    &:hover {}
  }

  &.animated:active {
    transform: scale(1.1);
    transition: none;
  }

  &[disabled] {
    background-color: ${props => props.theme.colors.mediumGrey};
    border-color: ${props => props.theme.colors.mediumGrey};
  }
`

export default Button;
