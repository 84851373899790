import hash from 'object-hash'
import { actionTypes } from '../actions/store'

const initialState = {
  id: null,
  name: null,
  logo: null,
  headerImage: null,
  isOpen: true,
  openingHours: [],
  deliveryEstimate: null,
  minimumOrder: null,
  pickupPrice: null,
  deliveryPrice: null,
  contactInfo: {
    phone: null,
    email: null
  },
  availableCardBrands: [],
  availableCities: [],
  menu: {
    categories: [],
    hash: null,
    filter: null
  },
  outRadius: false
}

const storeReducer = (state = initialState, action) => {
  const sessionStore = JSON.parse(localStorage.getItem('store'))
  const finalState = { value: state }

  if (!!sessionStore) {
    Object.assign(finalState, {
      value: sessionStore
    })
  }

  let newState = finalState.value

  switch (action.type) {
    case actionTypes.SET_OUT_RADIUS:
      newState = {
        ...state,
        outRadius: action.payload
      }

      break

    case actionTypes.SET_NO_DELIVERY:
      newState = {
        ...state,
        ...action.payload
      }

      break

    case actionTypes.SET_DELIVERY_COUNTER:
      newState = {
        ...state,
        ...action.payload
      }

      break

    case actionTypes.SET_DELIVERY_PICKUP:
      newState = {
        ...state,
        ...action.payload
      }

      break

    case actionTypes.SET_STORE_ID: {
      newState = {
        ...state,
        id: action.payload,
      };
      break;
    }
    case actionTypes.SET_AVAILABLE_CITIES: {
      newState = {
        ...state,
        availableCities: action.payload,
      };
      break;
    }
    case actionTypes.SET_AVAILABLE_STORES: {
      newState = {
        ...state,
        availableStores: action.payload,
      };
      break;
    }
    case actionTypes.SET_STORE_INFO: {
      const store = action.payload;

      store.menu.hash = hash(store.menu.categories);
      newState = {
        ...state,
        ...store,
      };
      break;
    }

    case actionTypes.SET_STORE_DATA: {
      const store = action.payload;
      newState = {
        ...state,
        ...store,
      };
      break;
    }

    case actionTypes.DELIVERY_PRICE_INFO: {
      const {
        modalities,
        minimumOrder,
        deliveryPrice,
        pickupPrice
      } = action.payload;

      return {
        ...state,
        modalities,
        minimumOrder,
        deliveryPrice,
        pickupPrice,
      };
    }

    case actionTypes.APPLY_FILTER: {
      return {
        ...state,
        menu: {
          ...state.menu,
          filter: action.payload,
        },
      };
    }
    case actionTypes.RESET_SESSION: {
      newState = initialState;
      break;
    }
    default: {
      return state;
    }
  }

  localStorage.setItem('store', JSON.stringify(newState));
  return newState;
};

export default storeReducer;
