import React from 'react';
import styled from 'styled-components';

import { TextInput, LoadingOverlay } from '../common'
import { Form } from '../common/form/'
import { notEmpty, isCep } from '../common/form/validationFunctions'

import { device } from '../../constants/responsive'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 35px;
  min-width: 320px;

  @media ${device.desktop} {
  	min-width: 360px;
  }
`

const Title = styled.h1`
  flex: 1;
  font-size: 24px;
  font-weight: bold;
  color: #000;
  border-bottom: #000 1px solid;
  min-width: 100%;
  text-align: center;
	margin: 0px;
	padding: 0px;
  margin-bottom: 30px;
  padding-bottom: 20px;
`

const Subtitle = styled.h2`
  flex: 1;
  font-size: 20px;
  font-weight: bold;
  color: #000;
  min-width: 100%;
  text-align: center;
	margin: 0px;
	padding: 0px;
  margin-bottom: 30px;
`

const Paragraph = styled.p`
  flex: 1;
  font-size: 18px;
  color: #000;
  min-width: 100%;
  text-align: center;
  margin-bottom: 30px;
`

const Button = styled.button`
  background: #9C9C9C;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 18px;
  text-transform: uppercase;
  color: #fff;
  border-radius: 10px;
  flex: 1;
  min-width: 100%;

  &:last-child {
    margin-bottom: 0px;
  }
`

const Content = styled.div`
  margin: 0x;
	margin-bottom: 30px;
  width: 100%;
  text-align: center;

  &:last-child {
    margin-bottom: 0px;
  }
`

const Span = styled.span`
  font-size: 20px;
  font-style: italic;
	color: #AAABAE;
	margin-top: 20px;
	margin-bottom: 20px;
`

class CepSearch extends React.Component {

  constructor(props) {
    super(props);

    this.formInputs = this.setupForms();
  }

  setupForms = () => [
    {
      id: 'cep',
      validations: [notEmpty, isCep],
      Component: (formInputProps) => (
        <TextInput {...formInputProps} placeholder="00000-000" mask="99999-999" center />
      )
    }
  ]

  handleSearchCep = ({ cep }) => {
    this.props.addEventGTM({
      'event': 'modalidade_retirada',
      'modalidade_retirada': 'delivery'
    })

    this.props.searchCep(cep);
  }

  render() {
    return (
      <Form formInputs={this.formInputs} onSubmit={this.handleSearchCep}>
        {(inputs) => {
          return (
            <Wrapper>
              <LoadingOverlay visible={this.props.loading} margin={"5px"} />

              <Title>Delivery</Title>

              <Subtitle>Informe seu CEP</Subtitle>

              <Paragraph>
                <em>Informe seu CEP para podermos verificar a disponibilidade de entrega em sua região.</em>
              </Paragraph>

              <Content>
                <inputs.cep />
              </Content>

              <Button type="submit">
                BUSCAR CEP
              </Button>

              <Span>Ou</Span>

              <Button type="button" onClick={() => {
                this.props.handlePickupModality()
              }}>
                RETIRAR NA LOJA
              </Button>
            </Wrapper>
          )
        }}
      </Form>
    )
  }
}

export default CepSearch
