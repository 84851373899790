import { actionTypes as requestsActionTypes } from './requests'
import { setNavCurrentList } from './ui'
import {
  mapAPIStoreInfo,
  mapClosestStoreRequest,
  mapClosestStoreReponse,
  mapAvailableCitiesResponse,
  mapAvailableCitiesRequest,
  mapAvailableStoresRequest,
  mapAvailableStoresResponse,
  mapDeliveryPriceInfo
} from '../../dto/store'
import { mapFridgeData } from '../../dto/fridge'
import withAuthHeader from '../../utils/withAuthHeader'

import handleRequestError from '../../utils/handleRequestError'

export const actionTypes = {
  SET_STORE_INFO: 'SET_STORE_INFO',
  SET_STORE_DATA: 'SET_STORE_DATA',
  SET_STORE_ID: 'SET_STORE_ID',
  APPLY_FILTER: 'APPLY_FILTER',
  SET_AVAILABLE_CITIES: 'SET_AVAILABLE_CITIES',
  SET_AVAILABLE_STORES: 'SET_AVAILABLE_STORES',
  RESET_SESSION: 'RESET_SESSION',
  DELIVERY_PRICE_INFO: 'DELIVERY_PRICE_INFO',
  SET_DELIVERY_PICKUP: 'SET_DELIVERY_PICKUP',
  SET_NO_DELIVERY: 'SET_NO_DELIVERY',
  SET_DELIVERY_COUNTER: 'SET_DELIVERY_COUNTER',
  SET_OUT_RADIUS: 'SET_OUT_RADIUS'
}

export const fetchEcommerceData = (storeId) => async (dispatch, getState, api) => {
  try {
    dispatch({
      type: requestsActionTypes.REQUEST_STORE,
      payload: {
        loading: true
      }
    })

    const address = getState().cart.delivery.address
    const cart = JSON.parse(localStorage.getItem('cart'))

    const url = (address ? 
      `Estabelecimentos/Obter/${storeId}?enderecoDeliveryId=${address.id}`
      : (cart.delivery.modality === 4 ?
        `Estabelecimentos/Obter/${storeId}?delivery=${true}`
        : `Estabelecimentos/Obter/${storeId}`))
    const result = await api.get(url, withAuthHeader())

    delete result.data.ModalidadesRetirada

    const dto = mapAPIStoreInfo(result.data)

    dispatch({
      type: actionTypes.SET_STORE_INFO,
      payload: { ...dto }
    })

    dispatch({
      type: requestsActionTypes.REQUEST_STORE,
      payload: {
        loading: false,
        success: true
      }
    })
  } catch (e) {
    dispatch({
      type: requestsActionTypes.REQUEST_STORE,
      payload: {
        loading: false,
        success: false,
        error: e
      }
    })
  }
}


// OLD

export const getDeliveryFee = (latitude,
  longitude) => async (dispatch, getState, api) => {
  try {
    dispatch({
      type: requestsActionTypes.REQUEST_STORE,
      payload: {
        loading: true
      }
    })

    const url = `Estabelecimentos/ObterTaxaEntrega/${getState().store.id}?latitude=${latitude}&longitude=${longitude}`
    const result = await api.get(url, withAuthHeader())

    delete result.data.Categorias

    const dto = mapDeliveryPriceInfo(result.data)

    dispatch({
      type: actionTypes.DELIVERY_PRICE_INFO,
      payload: {
        ...dto
      }
    })

    dispatch({
      type: requestsActionTypes.REQUEST_STORE,
      payload: {
        loading: false,
        success: true
      }
    })
  } catch (error) {
    dispatch({
      type: requestsActionTypes.REQUEST_STORE,
      payload: {
        loading: false,
        success: false,
        error
      }
    })
  }
}

export const getStoreInfoAndMenu = (storeId) => async (dispatch, getState, api) => {
  try {
    dispatch({
      type: requestsActionTypes.REQUEST_STORE,
      payload: {
        loading: true
      }
    })

    const cart = getState().cart
    const delivery = cart && cart.delivery
    const deliveryAddress = delivery && delivery.address
    const user = getState().user

    const url = {
      value: `Estabelecimentos/Obter/${storeId}`
    }

    if (!user && !deliveryAddress) {
      Object.assign(url, {
        value: `Estabelecimentos/Obter/${ storeId }`
      })
    }

    if (!user && deliveryAddress && deliveryAddress.cep && deliveryAddress.number && deliveryAddress.latitude && deliveryAddress.longitude && !deliveryAddress.id && delivery !== 2) {
      Object.assign(url, {
        value: `Estabelecimentos/Obter/${ storeId }?cep=${ deliveryAddress.cep }&numero=${ deliveryAddress.number }&latitude=${ deliveryAddress.latitude }&longitude=${ deliveryAddress.longitude }`
      })
    }

    if (user && deliveryAddress && deliveryAddress.id) {
      Object.assign(url, {
        value: `Estabelecimentos/Obter/${ storeId }?enderecoDeliveryId=${ deliveryAddress.id }`
      })
    }

    /* if (!user && !deliveryAddress) {
      Object.assign(url, {
        value: `Estabelecimentos/Obter/${storeId}?cep=${deliveryAddress.cep}&numero=${deliveryAddress.number}&latitude=${deliveryAddress.latitude}&longitude=${deliveryAddress.longitude}`
      })
    }
    
    if (user && deliveryAddress) {
      Object.assign(url, {
        value: `Estabelecimentos/Obter/${storeId}?enderecoDeliveryId=${deliveryAddress.id}`
      })
    } */

    //OBTER
    /* const url = (address
      ? `Estabelecimentos/Obter/${storeId}?enderecoDeliveryId=${deliveryAddress.id}`
      : (cart && cart.delivery && cart.delivery.modality === 4
      ? `Estabelecimentos/Obter/${storeId}?delivery=${true}`
      : `Estabelecimentos/Obter/${storeId}`)) */

      

    const result = 
      await api.get(url.value, withAuthHeader())
        .catch(() => dispatch({
          type: actionTypes.SET_OUT_RADIUS,
          payload: true
        }))

    delete result.data.ModalidadesRetirada

    const dto = mapAPIStoreInfo(result.data)

    dispatch({
      type: actionTypes.SET_STORE_INFO,
      payload: { ...dto }
    })

    dispatch({
      type: actionTypes.SET_OUT_RADIUS,
      payload: false
    })

    dispatch({
      type: requestsActionTypes.REQUEST_STORE,
      payload: {
        loading: false,
        success: true
      }
    })

    if (!deliveryAddress) {
      await dispatch(getDeliveryFee())
    }

    if (deliveryAddress && deliveryAddress.latitude && deliveryAddress.longitude) {
      await dispatch(getDeliveryFee(deliveryAddress.latitude, deliveryAddress.longitude))
    }
  } catch (e) {
    dispatch({
      type: requestsActionTypes.REQUEST_STORE,
      payload: {
        loading: false,
        success: false,
        error: e
      }
    })
  }
}

export const applyMenuFilter = (filter) => async (dispatch) => {
  await dispatch(setNavCurrentList(0))
  dispatch({
    type: actionTypes.APPLY_FILTER,
    payload: filter
  })
}

export const getClosestStore = () => async (dispatch, getState, api) => {
  try {
    dispatch({
      type: requestsActionTypes.REQUEST_CLOSEST_STORE,
      payload: {
        loading: true
      }
    })
    const address = getState().cart.delivery.address
    const url = `Estabelecimentos/ListarDelivery`
    const dto = mapClosestStoreRequest(address)
    const result = await api.post(url, dto, withAuthHeader())
    const response = mapClosestStoreReponse(result.data)
    dispatch({
      type: actionTypes.SET_STORE_ID,
      payload: response.id
    })

    dispatch({
      type: requestsActionTypes.REQUEST_CLOSEST_STORE,
      payload: {
        loading: false,
        success: true
      }
    })
  } catch (e) {
    console.log(e)

    dispatch({
      type: requestsActionTypes.REQUEST_CLOSEST_STORE,
      payload: {
        loading: false,
        success: false,
        error: e
      }
    })
  }
}

export const getAvailableCities = (coords) => async (
  dispatch,
  getState,
  api
) => {
  try {
    dispatch({
      type: requestsActionTypes.REQUEST_AVAILABLE_CITIES,
      payload: {
        loading: true
      }
    })
    const url = `Estabelecimentos/Listar`
    const body = mapAvailableCitiesRequest(coords)
    const result = await api.post(url, body, withAuthHeader())
    const response = mapAvailableCitiesResponse(result.data)
    dispatch({
      type: actionTypes.SET_AVAILABLE_CITIES,
      payload: response
    })

    dispatch({
      type: requestsActionTypes.REQUEST_AVAILABLE_CITIES,
      payload: {
        loading: false,
        success: true
      }
    })
  } catch (e) {
    console.log(e)

    dispatch({
      type: requestsActionTypes.REQUEST_AVAILABLE_CITIES,
      payload: {
        loading: false,
        success: false,
        error: e
      }
    })
  }
}

export const getAvailableStores = ({ city, coords }) => async (
  dispatch,
  getState,
  api
) => {
  try {
    dispatch({
      type: requestsActionTypes.REQUEST_AVAILABLE_STORES,
      payload: {
        loading: true
      }
    })

    dispatch({
      type: actionTypes.SET_AVAILABLE_STORES,
      payload: []
    })

    const url = `Estabelecimentos/Listar`
    const body = mapAvailableStoresRequest({ city, coords })
    const result = await api.post(url, body, withAuthHeader())
    const response = mapAvailableStoresResponse(result.data)

    dispatch({
      type: actionTypes.SET_AVAILABLE_STORES,
      payload: response
    })
    dispatch({
      type: requestsActionTypes.REQUEST_AVAILABLE_STORES,
      payload: {
        loading: false,
        success: true
      }
    })
  } catch (e) {
    console.log(e)
    dispatch({
      type: requestsActionTypes.REQUEST_AVAILABLE_STORES,
      payload: {
        loading: false,
        success: false,
        error: e
      }
    })
  }
}

export const setSelectedStore = (storeId) => async (dispatch) => {
  dispatch({
    type: actionTypes.SET_STORE_ID,
    payload: storeId
  })
}

export const setDeliveryPickup = () => ({
  type: actionTypes.SET_DELIVERY_PICKUP,
  payload: {
    deliveryPrice: 0
  }
})

export const setDeliveryCounter = () => ({
  type: actionTypes.SET_DELIVERY_COUNTER,
  payload: {
    deliveryPrice: 0
  }
})

export const setNoDelivery = () => ({
  type: actionTypes.SET_NO_DELIVERY,
  payload: {
    deliveryPrice: 0,
    pickupPrice: 0
  }
})

export const fetchFridgeData = () => async (dispatch, getState, api) => {
  const result = { value: null }

  try {
    const url = `Estabelecimentos/ObterDadosEstabelecimento/${getState().store.id}`
    const apiResult = await api.get(url, withAuthHeader())

    const response = mapFridgeData(apiResult.data)

    if (response.success) {
      Object.assign(result, {
        value: response
      })

      dispatch({
        type: actionTypes.SET_STORE_DATA,
        payload: result.value
      })

      return
    }

    Object.assign(result, {
      value: null
    })
  } catch (e) {
    const error = handleRequestError(e)    

    Object.assign(result, {
      value: {
        error: error.message,
        success: false
      }
    })
  } finally {
    return result.value
  }
}