import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { Box } from '../common/'
import { MenuList, MenuNav } from './'
import { device } from '../../constants/responsive';
import normalizeString from '../../utils/normalizeString';
import { setNavCurrentList } from '../../redux/actions/ui';
import { handleGTM } from '../../redux/actions/gtm'
import { numberToCurrency } from '../../utils';

const Wrapper = styled.div`
    position: relative;
    flex-grow: 1;
`

const ContentBox = styled(Box)`
    padding: 5px 10px 10px 10px;

    @media ${device.mobile} {
        border-radius: 0;
    }

    @media ${device.desktop} {
        width: auto;
        padding: 0 42px 42px 42px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
`

class Menu extends React.Component {
    componentDidMount() {
        setTimeout(() => {
            this.addEventNewGTM()
        }, 10000);

    }

    addEventNewGTM() {
        const selectedModality = (this.props.modalities && (this.props.modalities.filter(filteredItem => filteredItem.id === this.props.deliveryModality))[0]) || null

        const minimumValueText = ((this.props.deliveryModality && selectedModality) ?
            (selectedModality.minimumValue ?
                numberToCurrency(selectedModality.minimumValue) :
                'Nenhum') : '')

        const serviceFeeText = ((this.props.deliveryModality && selectedModality) ?
            (selectedModality.serviceFee ?
                numberToCurrency(selectedModality.serviceFee) :
                'Grátis') : '')

        const dataGTM = {
            'event': 'Valores Minimos',
            'taxa de entrega': serviceFeeText,
            'valor minimo': minimumValueText
        }

        this.props.handleGTM(dataGTM)
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.hash !== this.props.hash ||
            nextProps.loading !== this.props.loading ||
            nextProps.fixedNav !== this.props.fixedNav ||
            nextProps.currentList !== this.props.currentList ||
            nextProps.categories !== this.props.categories) {
            return true;
        }
        return false;
    }

    render() {
        const { categories, fixedNav, loading, currentList, setNavCurrentList, handleGTM, queryProduct } = this.props;

        return (
            <Wrapper>
                <MenuNav categories={categories} fixed={fixedNav} />
                <ContentBox>
                    <MenuList
                        categories={categories}
                        loading={loading}
                        currentList={currentList}
                        queryProduct={queryProduct}
                        setNavCurrentList={setNavCurrentList}
                        handleGTM={handleGTM}
                    />
                </ContentBox>
            </Wrapper>
        )
    }
}


const filterCategories = (categories, term) => {
    if (!categories) return categories;
    if (!term || term === '') return categories;
    const normalizedTerm = normalizeString(term.toLowerCase());
    const newCategories = [];
    categories.forEach(category => {
        const newCategory = { ...category };
        newCategory.products = newCategory.products.filter(product => {
            const normalizedName = normalizeString(product.name.toLowerCase());
            const normalizedDescription = product.description ? normalizeString(product.description.toLowerCase()) : '';
            if (normalizedName.includes(normalizedTerm)
                || (normalizedDescription.includes(normalizedTerm))) {
                return true;
            }
            return false;
        })
        if (newCategory.products.length > 0) {
            newCategories.push(newCategory)
        }
    })
    return newCategories
}

const mapStateToProps = (state) => {
    const categories = [...state.store.menu.categories];
    const filter = state.store.menu.filter;

    return {
        categories: filterCategories(categories, filter),
        hash: state.store.menu.hash,
        loading: state.requests.storeInfo.loading,
        currentList: state.ui.navCurrentList,
        name: state.store.name
    }
}


export default connect(mapStateToProps, { setNavCurrentList, handleGTM })(Menu);