import React from 'react'
import styled from 'styled-components'
import { connect } from "react-redux"
import Skeleton from 'react-loading-skeleton'

import { Button } from '../common/'
import { numberToCurrency } from '../../utils'
import MenuProductImage from './MenuProductImage'
import { ProductDetailModal } from '../product'
import { addCartItem } from '../../redux/actions/cart'
import {
    toggleAddressModal,
    toggleLoginModal
} from '../../redux/actions/ui'
import textLimiter from '../../utils/textLimiter'
import { Desktop, Mobile } from '../common/ResponsiveComponent'
import { device } from '../../constants/responsive'
import { MODAL_STATES as ADDRESS_MODAL_STATES } from '../address/AddressModal'
import { handleGTM } from '../../redux/actions/gtm'

import { CARDAPIO_CONSULTA } from "../../config"

const CardWrapper = styled.div`
    display: flex;
    background-color: white;
    height: 100px;
    cursor: pointer;

    @media ${device.desktop} {
        height: 140px;
    }
`

const ImageWrapper = styled.div`
    display: flex;
    padding-right: 18px;
    align-items: center;
`

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

const ProductName = styled.span`
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.06em;
    color: ${props => props.theme.colors.textBold};

    @media ${device.desktop} {
        font-size: 18px;
        margin-top: 9px;
        margin-bottom: 5px;
    }
`

const ProductDescription = styled.span`
    font-size: 11px;
    letter-spacing: -0.01em;
    flex-grow: 1;
    font-weight: 300;
    text-overflow: ellipsis;
    color: ${props => props.theme.colors.textPrimary};

    @media ${device.desktop} {
        font-size: 14px;
    }

    @media (min-width: 960px) {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 12rem;
        min-height: 1.5rem;

        p {
            white-space: break-spaces;
            overflow: hidden;
            display: inline-block;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }
    }

    @media (min-width: 1024px) {
        max-width: 18rem;
    }

    @media (min-width: 1280px) {
        max-width: 25rem;
    }

    @media (min-width: 1400px) {
        max-width: 30rem;
    }

    @media (min-width: 1440px) {
        text-overflow: unset;
        white-space: normal;
        overflow: visible;
        max-width: none;
    }

`

const ButtonWrapper = styled.div`
    text-align: right;
`

const CustomButton = styled(Button)`
    @media (min-width: 960px) {
        width: 100px;
    }

    @media (min-width: 1024px) {
        width: 110px;
    }

    @media (min-width: 1280px) {
        width: 152px;
    }
`;

const PriceAndButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    ${(props) => props && props.isPromotionalPrice && `
            flex-wrap: wrap;
            ${ButtonWrapper} {
                margin: 3px 0 0 auto;
            }
    `}

    @media (min-width: 1080px) {
        flex-wrap: unset;
        ${ButtonWrapper} {
            margin: 0;
        } 
    }
`

const PriceWrapper = styled.div`
    font-size: 15px;
    font-weight: 500;
    color: ${props => props.theme.colors.textBold};
    text-align: left;

    @media ${device.desktop} {
        font-size: 18px;
    }
`

const PromotionalOldPrice = styled.span`
    font-weight: 400;
    font-size: 12px;
    color: ${props => props.theme.colors.mediumGrey};
    opacity: .8;
    text-decoration: line-through;
    margin-bottom: 1px;

    @media ${device.desktop} {
        font-size: 15px;
    }
`;

const PromotionalCurrentPrice = styled.span`
    font-weight: 700;
    font-size: 15px;
    color: ${props => props.theme.colors.lightGreen};
    margin-right: 10px;

    @media ${device.desktop} {
        font-size: 18px;
    }
`;

const PromotionalPriceWrapper = styled.div`
    align-items: flex-end;
    align-self: flex-start;
    display: flex;

    ${PromotionalCurrentPrice} {
        order: -1;
    }
`;

class MenuProductCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: props.isActive,
            addressModalCallback: null
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.isModalOpen !== this.state.isModalOpen ||
            (
                nextState.addressModalCallback !== this.state.addressModalCallback &&
                nextProps.addressModalOpen !== this.props.addressModalOpen
            ) ||
            nextProps.deliveryModality !== this.props.deliveryModality ||
            nextProps.hasProductInCart !== this.props.hasProductInCart ||
            nextProps.product !== this.props.product
        ) {
            return true;
        }
        return false;
    }

    componentDidUpdate(prevProps) {
        // Address modal closed?
        if (!this.props.addressModalOpen && prevProps.addressModalOpen) {
            // Has completed address?
            if (this.state.addressModalCallback && this.props.deliveryModality) {
                this.state.addressModalCallback();
                this.setState({ addressModalCallback: null });
            }
        }
    }

    addGTMEvent = (args) => {
        this.props.handleGTM(args)
    }

    handleOpenModal = () => {
        if (!this.props.deliveryModality) {
            this.props.toggleAddressModal({ show: true, state: ADDRESS_MODAL_STATES.CEP_SEARCH });
            this.setState({ addressModalCallback: this.handleOpenModal })

            return
        }

        if (this.props.product) {
            this.setState({ isModalOpen: true });
        }
    }

    hasMandatoryAddon = () => {
        let hasMandatory = false;
        this.props.product && this.props.product.addons.forEach(addon => {
            if (addon.min > 0) hasMandatory = true;
        })
        return hasMandatory;
    }

    handleAddToCart = (e) => {
        if (e) {
            e.stopPropagation()
        }

        if (this.hasMandatoryAddon()) {
            this.handleOpenModal()

            return
        }

        if (!this.props.deliveryModality) {
            this.props.toggleAddressModal({ show: true, state: ADDRESS_MODAL_STATES.CEP_SEARCH })
            this.setState({ addressModalCallback: this.handleAddToCart })

            return
        }

        this.handleAddCartItem({ quantity: 1, checkedAddons: [], observation: null });
    }

    handleCloseModal = () => {
        this.setState({ isModalOpen: false });
    }

    handleAddCartItem = ({ quantity, checkedAddons, observation }) => {
        if (!this.props.user) {
            this.props.toggleLoginModal({ show: true, state: 0 })
            return
        }

        if (this.props.user.signUpStatus === 2) {
            this.props.toggleLoginModal({ show: true, state: 1 })
            return
        }


        if ((!this.props.data.step || this.props.data.step === 1) && this.props.user.signUpStatus !== 1) {
            this.props.toggleLoginModal({ show: true, state: 0 })
            return
        }

        const { promotionalPrice, ...product } = this.props.product
        if (promotionalPrice) {
            product.price = promotionalPrice
        }

        this.props.addCartItem({
            product,
            quantity,
            checkedAddons,
            observation
        })

        this.addGTMEvent({
            'event': `add_to_cart`,
            'ecommerce': {
                'currency': 'BRL',
                'items': [{
                    'item_name': product.name,
                    'item_id': product.id,
                    'price': product.price,
                    'discount': null,
                    'categoria': product.categoryName ?? null,
                    'quantity': 1
                }]
            }
        })
    }

    render() {
        const {
            product,
            hasProductInCart,
            storeName
        } = this.props;

        return (
            <>
                <CardWrapper onClick={() => {
                    this.handleOpenModal()

                    const gtmEventData = {
                        'event': `PRODUTO - ${product.name}`,
                        'ecommerce': {
                            'click': {
                                'product': {
                                    'name': product.name,
                                    'id': product.id,
                                    'price': product.price,
                                    'brand': null,
                                    'variant': null,
                                    'list': `Cardapio - ${storeName}`,
                                    'position': null
                                }
                            }
                        }
                    }

                    this.addGTMEvent(gtmEventData)
                }}>
                    {product ? product.image && (
                        <ImageWrapper>
                            <MenuProductImage image={product.image} />
                        </ImageWrapper>
                    ) : (
                        <ImageWrapper>
                            <Skeleton width={100} height={100} />
                        </ImageWrapper>
                    )}
                    <ContentWrapper>
                        <ProductName>
                            {product && product.name ? product.name : <Skeleton />}
                        </ProductName>
                        <ProductDescription>
                            <Desktop>
                                <p>
                                    {product && product.description
                                        ? textLimiter(product.description, 200, true)
                                        : <Skeleton count={3} />
                                    }
                                </p>
                            </Desktop>
                            <Mobile>
                                <p>
                                    {product && product.description
                                        ? textLimiter(product.description, 60, true)
                                        : <Skeleton count={3} />
                                    }
                                </p>
                            </Mobile>
                        </ProductDescription>
                        <PriceAndButtonWrapper isPromotionalPrice={product && product.promotionalPrice}>
                            {
                                product && product.promotionalPrice ? <PromotionalPriceWrapper>
                                    <PromotionalCurrentPrice>
                                        {numberToCurrency(product.promotionalPrice)}
                                    </PromotionalCurrentPrice>
                                    <PromotionalOldPrice>
                                        {product && product.price ? numberToCurrency(product.price) : <Skeleton />}
                                    </PromotionalOldPrice>
                                </PromotionalPriceWrapper> :
                                    <PriceWrapper>
                                        {product && product.price
                                            ? product.price !== 0 && numberToCurrency(product.price)
                                            : <Skeleton />
                                        }
                                    </PriceWrapper>
                            }
                            {CARDAPIO_CONSULTA === "false" && <ButtonWrapper>
                                {product
                                    ? (
                                        <CustomButton big className={hasProductInCart ? "primary animated" : "default animated"} onClick={this.handleAddToCart}>
                                            {hasProductInCart ? `ADICIONAR + 1` : `ADICIONAR`}
                                        </CustomButton>
                                    )
                                    : <Skeleton />}
                            </ButtonWrapper>}
                        </PriceAndButtonWrapper>
                    </ContentWrapper>
                </CardWrapper>
                <ProductDetailModal
                    isOpen={this.state.isModalOpen}
                    onClose={this.handleCloseModal}
                    onUpdateCartItem={this.handleAddCartItem}
                    product={product}
                    addGTMEvent={this.addGTMEvent}
                />
            </>
        )
    }
}

MenuProductCard.defaultProps = {
    isActive: false
}

const mapStateToProps = (state, ownProps) => {
    return {
        deliveryModality: state.cart.delivery && state.cart.delivery.modality,
        addressModalOpen: state.ui.modals.addressModal.show,
        hasProductInCart: ownProps.product && !!state.cart.items.find(item => item.product.id === ownProps.product.id),
        data: state.requests.save,
        user: state.user,
        storeName: state.store.name,
    }
}

export default connect(
    mapStateToProps, {
    addCartItem,
    toggleAddressModal,
    toggleLoginModal,
    handleGTM
}
)(MenuProductCard)
