import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'; 
import { isMobile } from 'react-device-detect';

import { fetchPaymentMethods, fetchAvailableCardBrands, deleteCard, addCard, addTicketCard } from '../../redux/actions/payment';
import { navigateTo, goBack } from '../../redux/actions/pages';
import { setPaymentType, setCpfOnReceit, setPaymentCashChange } from '../../redux/actions/cart';

import { Box, RadioButton, Button, LoadingOverlay, ConfirmModal, Icon } from '../common/';
import { Mobile, Desktop } from '../common/ResponsiveComponent';
import { formatCpf } from '../../utils'; 
import { AddNewCardModal } from './AddNewCardModal/';
import OfflinePaymentBlock from './OfflinePaymentBlock';
import CashChangeModal from './CashChangeModal';

import { device } from '../../constants/responsive';
import { PAYMENT_TYPES, CARD_BRANDS_IDS } from '../../constants/payment';

import { CpfSelection, OnlinePaymentBlock } from '.';

import { handleGTM } from '../../redux/actions/gtm'

const Wrapper = styled.div`
    flex-grow: 1;

    @media ${device.desktop} {
        margin-top: 20px;
    }
`

const MainBox = styled(Box)`
    @media ${device.desktop} {
        padding: 20px 40px;
    }
`

const Content = styled.div`
    position: relative;

    @media ${device.mobile} {
        width: 100vw;
        height: calc(100vh - 55px - 55px);
        overflow-y: auto;
        padding: 20px 40px;
    }
`

const TitleBlock = styled.div`

    @media ${device.mobile} {
        height: 55px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid ${props => props.theme.colors.lightGrey}
    }

    @media ${device.desktop} {
        position: relative;
        padding: 10px 0;
    }
`

const ContentBlock = styled.div`
    position: relative;
    padding: 10px 0;
`

const OnlineDropdown = styled.div`
    overflow: hidden;
    max-height: 0;
    transition: all 0.3s;
    margin: 10px 0 20px 0;

    ${props => props.visible && `
        max-height: 1000px;
    `}
`

const OfflineDropdown = styled.div`
    overflow: hidden;
    max-height: 0;
    transition: all 0.3s;
    margin: 10px 0;

    ${props => props.visible && `
        max-height: 1000px;
    `}
`

const TitleText = styled.span`
    font-size: 18px;
    font-weight: 700;
    color: ${props => props.theme.colors.primary};
`

const BackButton = styled(Button)`

    @media ${device.desktop} {
        font-size: 13px;
        width: 180px !important;
        height: 40px !important;
        margin-top: 30px;
        font-weight: 500;
    }

    @media ${device.mobile} {
        background: transparent;
        border: none;
        width: 64px;
        height: 32px;
    }   
`

const ContinueButton = styled(Button)`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 55px;
    border-radius: 0;
    display: block;
`

const PAYMENT_STATES = {
    SELECT_PAYMENT: 0,
    ONLINE_PAYMENT: 1,
    OFFLINE_PAYMENT: 2
}

class PaymentSelectBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentState: PAYMENT_STATES.SELECT_PAYMENT,
            showDeleteCardModal: false,
            showAddNewCardModal: false,
            deleteCard: null,
            addNewCardError: null,
            selectedCard: {
                card: null,
                isOffline: false
            }
        }
    }

    componentDidUpdate() {
        if (!isMobile && this.state.selectedCard.card) {
            this.handleContinueClick();
        }
    }

    componentDidMount() {
        this.props.fetchPaymentMethods();
        this.props.fetchAvailableCardBrands();
    }

    handleDeleteCard = (card) => {
        this.setState({showDeleteCardModal: true, deleteCard: card});
    }

    handleCloseDeleteCardModal = () => {
        this.setState({showDeleteCardModal: false, deleteCard: null});
    }

    handleConfirmDeleteCard = async () => {
        const result = await this.props.deleteCard(this.state.deleteCard);
        if (result.success) {
            this.setState({ showDeleteCardModal: false, deleteCard: null });
            this.props.fetchPaymentMethods();
        } else {
            this.props.showNotification({type: 'error', text: result.error})
        }
    }

    handleShowCashChangeModal = () => {
        this.setState({showCashChangeModal: true});
    }

    handleCloseCashChangeModal = () => {
        this.props.setPaymentCashChange(null)
        this.setState({showCashChangeModal: false});
        this.handleCardSelect(this.state.cashChangeCallbackCard, true, true);
    }

    handleConfirmCashChange = (change) => {
        this.props.setPaymentCashChange(change)
        this.setState({showCashChangeModal: false});
        this.handleCardSelect(this.state.cashChangeCallbackCard, true, true);
    }

    handleOnlineCardSelect = (card, check) => {
        this.handleCardSelect(card, check, false)
    }

    handleOfflineCardSelect = (card, check) => {
        if (card.paymentType === PAYMENT_TYPES.CASH) {
            this.setState({cashChangeCallbackCard: card})
            this.handleShowCashChangeModal();
        } else {
            this.handleCardSelect(card, check, true)
        }
    }

    handleCardSelect = (card, check, isOffline) => {
        if (check) {
            this.setState({selectedCard: {
                card,
                isOffline
            }})
        } else {
            this.setState({selectedCard: {card: null, isOffline: null}})
        }
    }

    handleAddNewCard = async (card) => {
        let result;
        if (card.brandId === CARD_BRANDS_IDS.TICKET_VR || card.brandId === CARD_BRANDS_IDS.TICKET_VA) {
            result = await this.props.addTicketCard(card);
        } else {
            result = await this.props.addCard(card);
        }
        if (result.success) {
            this.setState({selectedCard: {card: card, isOffline: false}})
            this.handleCloseAddNewCardModal();
            this.props.fetchPaymentMethods();
        } else {
            this.setState({addNewCardError: result.error})
        }
    }

    handleDismissAddNewCardError = () => {
        this.setState({addNewCardError: null})
    }

    handleShowAddNewCardModal = () => {
        this.setState({
            showAddNewCardModal: true
        })
    }

    handleCloseAddNewCardModal = () => {
        this.setState({
            showAddNewCardModal: false
        })
    }

    renderOnlinePayment = () => {
        const visible = this.state.currentState === PAYMENT_STATES.ONLINE_PAYMENT;
        return (
            <OnlineDropdown visible={visible}>
                <OnlinePaymentBlock 
                    loading={this.props.fetchPaymentMethodsLoading} 
                    cards={this.props.onlineCards}
                    onCheckCard={this.handleOnlineCardSelect}
                    onDeleteCard={this.handleDeleteCard}
                    onAddCard={this.handleShowAddNewCardModal}
                    selected={this.state.selectedCard.card}
                />
            </OnlineDropdown>
        )
    }

    renderOfflinePayment = () => {
        const visible = this.state.currentState === PAYMENT_STATES.OFFLINE_PAYMENT;
        return (
            <OfflineDropdown visible={visible}>
                <OfflinePaymentBlock 
                    loading={this.props.fetchPaymentMethodsLoading} 
                    cards={this.props.offlineCards}
                    onCheckCard={this.handleOfflineCardSelect}
                    selected={this.state.selectedCard.card}
                />
            </OfflineDropdown>
        )
    }

    renderDeleteCardModal = () => (
        <ConfirmModal
            isOpen={this.state.showDeleteCardModal}
            title={"Excluir cartão"}
            description={"Você tem certeza que deseja excluir esse cartão?"}
            onClose={this.handleCloseDeleteCardModal}
            positiveAction={{
                text:"Sim",
                onClick: this.handleConfirmDeleteCard
            }}
            negativeAction={{
                text:"Não",
                onClick: this.handleCloseDeleteCardModal
            }}
        />
    )

    renderCashChangeModal = () => {
        const taxPrice = this.props.deliveryModality === 4 ? this.props.deliveryPrice : (
            this.props.deliveryModality === 2 ? this.props.pickupPrice : 0
        )

        return <CashChangeModal 
            isOpen={this.state.showCashChangeModal} 
            onConfirm={this.handleConfirmCashChange}
            onClose={this.handleCloseCashChangeModal} 
            total={this.props.cartTotal + taxPrice}
        />
    }

    renderAddNewCardModal = () => (
        <AddNewCardModal 
            isOpen={this.state.showAddNewCardModal} 
            onClose={this.handleCloseAddNewCardModal}
            addCard={this.handleAddNewCard}
            loading={this.props.addNewCardLoading}
            error={this.state.addNewCardError}
            onDismissError={this.handleDismissAddNewCardError}
            brandsImages={this.props.brandsImages} />
    )

    handleOnlineCheck = (check) => {
        if (check && this.state.currentState !== PAYMENT_STATES.ONLINE_PAYMENT) {
            this.setState({selectedCard: {
                card: null,
            }})
            this.setState({currentState: PAYMENT_STATES.ONLINE_PAYMENT})
        }
    }

    handleOfflineCheck = (check) => {
        if (check && this.state.currentState !== PAYMENT_STATES.OFFLINE_PAYMENT) {
            this.setState({selectedCard: {
                card: null,
            }})
            this.setState({currentState: PAYMENT_STATES.OFFLINE_PAYMENT})
        }
    }

    handleOnBackClick = () => {
        if (isMobile) {
            this.props.goBack('/cart');
        } else {
            this.props.goBack('/');
        }
    }

    handleContinueClick = () => {
        const { card, isOffline } = this.state.selectedCard;
        this.props.setPaymentType({type: card.paymentType, card, isOffline})
        if (isMobile) {
            this.props.goBack('/cart');
        }
    }

    isContinueButtonVisible = () => {
        if (!isMobile) return false;
        if (this.state.selectedCard.card && !this.state.showCashChangeModal) return true;
        return false;
    }

    render() {
        return (
            <Wrapper>
                <Mobile>
                    <TitleBlock>
                        <BackButton className="hollow" onClick={this.handleOnBackClick}>
                            <Icon name="arrowLeft" />
                        </BackButton>
                        <TitleText>FORMA DE PAGAMENTO</TitleText>
                    </TitleBlock>
                </Mobile>
                <MainBox>
                    <Desktop>
                        <TitleBlock>
                            <TitleText>FORMA DE PAGAMENTO</TitleText>
                        </TitleBlock>
                    </Desktop>
                    <Content>
                        {this.props.fetchPaymentMethodsLoading 
                            ? (
                                <ContentBlock>
                                    <LoadingOverlay visible={true} />
                                </ContentBlock>)
                            : (
                                <>
                                    <ContentBlock>
                                        <CpfSelection checked={this.props.useCpf} cpf={formatCpf(this.props.cpf)} onCheck={this.props.setCpfOnReceit} />
                                    </ContentBlock>
                                    <ContentBlock>
                                        <RadioButton name="paymentType" defaultChecked={this.state.currentState === PAYMENT_STATES.ONLINE_PAYMENT} value="online" onChange={this.handleOnlineCheck}>
                                            Pagar Online
                                        </RadioButton>
                                        {this.renderOnlinePayment()}
                                        {this.props.offlineCards && this.props.offlineCards.length > 0 && (
                                            <RadioButton name="paymentType" defaultChecked={this.state.currentState === PAYMENT_STATES.OFFLINE_PAYMENT} value="offline" onChange={this.handleOfflineCheck}>
                                                Pagar na entrega
                                            </RadioButton>
                                        )}
                                        {this.renderOfflinePayment()}
                                    </ContentBlock>
                                </>
                            )
                        }
                        <Mobile>
                            <ContinueButton visible={this.isContinueButtonVisible()} className="primary" onClick={this.handleContinueClick}>CONTINUAR</ContinueButton>
                        </Mobile>
                    </Content>
                </MainBox>
                <Desktop>
                    <BackButton className="back" onClick={this.handleOnBackClick}>VOLTAR PARA CARDÁPIO</BackButton>
                </Desktop>
                {this.renderDeleteCardModal()}
                {this.renderCashChangeModal()}
                {this.renderAddNewCardModal()}
            </Wrapper>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        onlineCards: state.payment.onlineCards,
        offlineCards: state.payment.offlineCards,
        cpf: state.user && state.user.cpf,
        useCpf: state.cart.useCpf,
        cartTotal: state.cart.total,
        brandsImages: state.payment.brandsImages,
        fetchPaymentMethodsLoading: state.requests.fetchPaymentMethods.loading,
        addNewCardLoading: state.requests.addCard.loading,
        deliveryModality: state.cart && state.cart.delivery.modality,
        deliveryPrice: state.store.deliveryPrice,
        pickupPrice: state.store.pickupPrice,
        storeName: state.store.name,
        items: state.cart.items
    }
}


export default connect(
    mapStateToProps,
    {
        fetchPaymentMethods,
        fetchAvailableCardBrands,
        setPaymentType,
        setCpfOnReceit,
        navigateTo,
        goBack,
        deleteCard,
        addCard,
        addTicketCard,
        setPaymentCashChange,
        handleGTM
    },
)(withRouter(PaymentSelectBox))
